import React from "react";

export default function CareerPage() {
  return (
    <div>

      <h1>Hello Career</h1>

    </div>
  );
}