import React from "react";

export default function ProjectPage() {
  return (
    <div>

      <h1>Hello Project</h1>

    </div>
  );
}